import classnames from 'classnames';

import employerMainEmployerIndexPublishVacancyButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_employer_index_publish_vacancy_button_click';
import employerMainPageButtonClickExternal from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_page_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatNewLine, format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { useIsHeadHunterPlatform, useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHeadhunterKg } from 'src/hooks/useSites';
import LandingButton from 'src/pages/AnonymousEmployer/components/LandingButton';

import styles from './publish-vacancy.less';

const TrlKeys = {
    title: 'index.employer.title',
    subTitle: 'index.employer.subTitle',
    publishVacancy: 'index.employer.publishVacancy',
    footnote: 'index.employer.footnote',
};

const Vacancy: TranslatedComponent = ({ trls }) => {
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    const statisticsDate = useSelector((state) => state.anonymousEmployer.statisticsDate)!;
    const isHeadHunterPlatform = useIsHeadHunterPlatform();
    const isZpPlatform = useIsZarplataPlatform();
    const sendDV360Analytics = useDV360Analytics();

    const sendAnalytics = () => {
        employerMainPageButtonClickExternal();
        sendDV360Analytics('ANONYMOUS_EMPLOYER_PAGE_PUBLISH_VACANCY_BUTTON_CLICK');
        employerMainEmployerIndexPublishVacancyButtonClick();
    };

    const isHeadhunterKg = useIsHeadhunterKg();

    const landingButton = (
        <LandingButton
            Element={Link}
            data-qa="employer-index-publish-vacancy"
            to="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
            onClick={sendAnalytics}
        >
            <span className={styles.action}>{trls[TrlKeys.publishVacancy]}</span>
        </LandingButton>
    );

    return (
        <Column xs="4" s="6" m="12" l="16">
            <div className={styles.vacancyWrapper}>
                <div className={styles.main}>
                    <div
                        className={classnames(styles.title, styles.titleFontSizeOverride, {
                            [styles.titleFontSizeOverrideZp]: isZpPlatform,
                        })}
                        data-qa="employer-index-title"
                    >
                        {formatNewLine(trls[TrlKeys.title])}
                    </div>
                    {isHeadHunterPlatform ? (
                        <>
                            <VSpacing base={4} />
                            <div className={styles.text}>
                                <Text size={isXs ? TextSize.Medium : TextSize.Large} data-qa="employer-index-subtitle">
                                    {trls[TrlKeys.subTitle]}
                                </Text>
                            </div>
                            <VSpacing base={13} xs={8} />
                            {landingButton}
                            <VSpacing base={10} xs={4} />
                            {isHeadhunterKg ? (
                                <div data-qa="employer-index-publish-footnote"></div>
                            ) : (
                                <div className={styles.text}>
                                    <Text
                                        importance={TextImportance.Tertiary}
                                        size={isXs ? TextSize.Small : TextSize.Medium}
                                        data-qa="employer-index-publish-footnote"
                                    >
                                        <span suppressHydrationWarning>
                                            {format(trls[TrlKeys.footnote], {
                                                '{0}': statisticsDate,
                                            })}
                                        </span>
                                    </Text>
                                </div>
                            )}
                            <VSpacing base={14} />
                        </>
                    ) : (
                        <div className={styles.aloneLandingButton}>{landingButton}</div>
                    )}
                </div>
            </div>
        </Column>
    );
};

export default translation(Vacancy);

// tempexp_36944_file
import { FC } from 'react';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useIsMiddleAsia, useIsRabotaBy } from 'src/hooks/useSites';

import Benefits from 'src/pages/AnonymousEmployer/magritte/Benefits/Benefits';
import Candidates from 'src/pages/AnonymousEmployer/magritte/Candidates';
import CardSection from 'src/pages/AnonymousEmployer/magritte/CardSection/CardSection';
import HeroSection from 'src/pages/AnonymousEmployer/magritte/HeroSection/HeroSection';
import Price from 'src/pages/AnonymousEmployer/magritte/Price';
import PublishVacancy from 'src/pages/AnonymousEmployer/magritte/PublishVacancy/PublishVacancy';
import ResumeSearch from 'src/pages/AnonymousEmployer/magritte/ResumeSearch';
import SearchSteps from 'src/pages/AnonymousEmployer/magritte/SearchSteps';
import Support from 'src/pages/AnonymousEmployer/magritte/Support';

const MagritteAnonymousEmployer: FC = () => {
    const isZp = useIsZarplataPlatform();
    const isRabotaBy = useIsRabotaBy();
    const isMiddleAsia = useIsMiddleAsia();

    if (isRabotaBy) {
        return (
            <>
                <HeroSection>
                    <PublishVacancy />
                </HeroSection>
                <CardSection style="neutral">
                    <ResumeSearch />
                </CardSection>
                <Price />
                <CardSection style="extra-1">
                    <SearchSteps />
                </CardSection>
                <Benefits />
                <CardSection style="extra-1">
                    <Candidates />
                </CardSection>
                <Support />
            </>
        );
    }

    if (isMiddleAsia) {
        return (
            <>
                <HeroSection>
                    <PublishVacancy />
                </HeroSection>
                <CardSection style="neutral">
                    <ResumeSearch />
                </CardSection>
                <Price />
                <CardSection style="neutral">
                    <SearchSteps />
                </CardSection>
                <Support />
            </>
        );
    }

    if (isZp) {
        return (
            <>
                <HeroSection isWide>
                    <PublishVacancy />
                </HeroSection>
                <CardSection style="neutral">
                    <ResumeSearch />
                    <SearchSteps />
                </CardSection>
                <Benefits />
                <CardSection style="neutral">
                    <Candidates />
                </CardSection>
                <Support />
            </>
        );
    }

    return (
        <>
            <HeroSection>
                <PublishVacancy />
            </HeroSection>
            <CardSection style="neutral">
                <ResumeSearch />
            </CardSection>
            <Price />
            <CardSection style="extra-1">
                <SearchSteps />
            </CardSection>
            <Benefits />
            <CardSection style="extra-1">
                <Candidates />
            </CardSection>
            <Support />
        </>
    );
};

export default MagritteAnonymousEmployer;

// tempexp_36944_file
import employerMainChooseHiringOptionElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_choose_hiring_option_element_shown';
import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Title, VSpacing } from '@hh.ru/magritte-ui';
import {
    GiftOutlinedSize24,
    CheckCircleOutlinedSize24,
    ClockOutlinedSize24,
    CalendarOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';

import AnalyticsElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useIsHhru } from 'src/hooks/useSites';
import Centered from 'src/pages/AnonymousEmployer/magritte/Centered/Centered';

import PriceCard from 'src/pages/AnonymousEmployer/magritte/Price/PriceCard/PriceCard';
import { CardType } from 'src/pages/AnonymousEmployer/magritte/Price/types';
import usePriceSection from 'src/pages/AnonymousEmployer/magritte/Price/usePriceSection';

import styles from './price.less';

const TrlKeys = {
    title: 'anonEmployer.price.ru.title',
    vacancies: {
        action: 'anonEmployer.price.ru.vacancies.action',
        header: 'anonEmployer.price.ru.vacancies.header',
        description: 'anonEmployer.price.ru.vacancies.description',
        firstOption: 'anonEmployer.price.ru.vacancies.firstOption',
        secondOption: 'anonEmployer.price.ru.vacancies.secondOption',
    },
    vacanciesAlt: {
        action: 'anonEmployer.price.ru.vacancies.action',
        header: 'anonEmployer.price.ru.vacancies.header',
        description: 'anonEmployer.price.ru.vacancies.description',
        firstOption: 'anonEmployer.price.vacancies.alt.firstOption',
        secondOption: 'anonEmployer.price.vacancies.alt.secondOption',
    },
    candidates: {
        action: 'anonEmployer.price.ru.candidates.action',
        header: 'anonEmployer.price.ru.candidates.header',
        description: 'anonEmployer.price.ru.candidates.description',
        firstOption: 'anonEmployer.price.ru.candidates.firstOption',
        secondOption: 'anonEmployer.price.ru.candidates.secondOption',
    },
};

const Price: TranslatedComponent = ({ trls }) => {
    const priceRef = usePriceSection();
    const isHHRu = useIsHhru();
    return (
        <AnalyticsElementShownAnchor fn={employerMainChooseHiringOptionElementShown}>
            <Centered>
                <VSpacing default={80} />
                <div className={styles.container} ref={priceRef}>
                    <Title Element="h2" size="extra-large" alignment="center">
                        {trls[TrlKeys.title]}
                    </Title>
                    <VSpacing default={48} xs={40} />
                    <div className={styles.cardsContainer}>
                        <PriceCard
                            type={CardType.Vacancies}
                            action={trls[TrlKeys.vacancies.action]}
                            header={trls[TrlKeys.vacancies.header]}
                            description={trls[TrlKeys.vacancies.description]}
                            toLink="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
                            cardOptions={
                                isHHRu
                                    ? [
                                          {
                                              icon: <CheckCircleOutlinedSize24 initial="secondary" />,
                                              text: trls[TrlKeys.vacancies.firstOption],
                                          },
                                          {
                                              icon: <GiftOutlinedSize24 initial="secondary" />,
                                              text: trls[TrlKeys.vacancies.secondOption],
                                          },
                                      ]
                                    : [
                                          {
                                              icon: <CheckCircleOutlinedSize24 initial="secondary" />,
                                              text: trls[TrlKeys.vacanciesAlt.firstOption],
                                          },
                                          {
                                              icon: <CalendarOutlinedSize24 initial="secondary" />,
                                              text: trls[TrlKeys.vacanciesAlt.secondOption],
                                          },
                                      ]
                            }
                        />
                        <PriceCard
                            type={CardType.Candidates}
                            action={trls[TrlKeys.candidates.action]}
                            header={trls[TrlKeys.candidates.header]}
                            description={trls[TrlKeys.candidates.description]}
                            toLink="/auth/employer?backurl=/price/dbaccess?hhtmFrom=employer_index_content"
                            cardOptions={[
                                {
                                    icon: <CheckCircleOutlinedSize24 initial="secondary" />,
                                    text: trls[TrlKeys.candidates.firstOption],
                                },
                                {
                                    icon: <ClockOutlinedSize24 initial="secondary" />,
                                    text: trls[TrlKeys.candidates.secondOption],
                                },
                            ]}
                        />
                    </div>
                </div>
                <VSpacing default={48} />
            </Centered>
        </AnalyticsElementShownAnchor>
    );
};

export default translation(Price);

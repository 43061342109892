import { useMemo } from 'react';

import employerMainEmployeeSearchFormTouch from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_employee_search_form_touch';
import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Input, SearchInput as MagritteSearchInput, useBreakpoint } from '@hh.ru/magritte-ui';

import formatter from 'src/components/Forms/formatter';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import useSendSuggestAnalytics from 'src/components/SupernovaSearch/SmartSuggest/useSendAnalytics';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { buildDataProvider, getAutosuggestItems } from 'src/utils/suggest/buildDataProvider';

const MAX_LENGTH = 3000;
const VACANCY_SUGGEST = '/vacancysuggest/';

const TrlKeys = {
    moreThanMax: 'query.length.moreThanMax',
    placeholder: 'index.employer.search.placeholder.resume',
    submit: 'supernova.search.submit',
};

interface SuggestType {
    text: string;
}

interface SearchInputProps {
    onSuggestPick?: (suggest: SuggestType) => void;
    searchText: string;
    onSearchTextChange: (searchText: string) => void;
}

const SearchInput: TranslatedComponent<SearchInputProps> = ({
    trls,
    onSuggestPick,
    searchText,
    onSearchTextChange,
}) => {
    const { sendSuggestShownAnalytics, sendSuggestItemClickAnalytics } = useSendSuggestAnalytics();
    const { isXS } = useBreakpoint();

    const onSelectValidator = (value: string) => {
        if (value) {
            sendSuggestItemClickAnalytics({
                text: value,
            });
            onSearchTextChange(value);
            onSuggestPick?.({ text: value });
        }
        return true;
    };

    const dataProvider = useMemo(() => buildDataProvider(VACANCY_SUGGEST, '', formatter, getAutosuggestItems), []);

    return (
        <SuggestWithDefaultErrorPlaceholder
            inputValue={searchText}
            onSelectValidator={onSelectValidator}
            dataProvider={dataProvider}
            onDropOpen={sendSuggestShownAnalytics}
            input={{
                component: isXS ? MagritteSearchInput : Input,
                props: {
                    name: CriteriaKey.Text,
                    placeholder: trls[TrlKeys.placeholder],
                    clearable: true,
                    size: 'large',
                    autoComplete: 'off',
                    onChange: (val: string) => {
                        onSearchTextChange(val);
                    },
                    onFocus: () => {
                        employerMainEmployeeSearchFormTouch();
                    },
                    maxLength: MAX_LENGTH,
                    'data-qa': 'vacancysearch__keywords-input',
                },
            }}
        />
    );
};

export default translation(SearchInput);

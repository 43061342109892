import { VendorAnalytics } from '@hh.ru/analytics-js';
import employerMainFrequentSearchRequestButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_frequent_search_request_button_click';
import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Text } from '@hh.ru/magritte-ui';

import CustomChips from 'src/pages/AnonymousEmployer/magritte/CustomChips';

import styles from './styles.less';

interface FrequentlySearchProps {
    items: string[];
    onItemClick: (name: string) => void;
}

const TrlKeys = {
    resume: 'index.employer.search.frequently.resume',
};

const FrequentlySearch: TranslatedComponent<FrequentlySearchProps> = ({ trls, items, onItemClick }) => {
    const handleClick = (name: string): void => {
        VendorAnalytics.eventToStorage('anonymous', 'click', 'button_pressed');
        employerMainFrequentSearchRequestButtonClick({
            professionName: name,
        });
        onItemClick(name);
    };

    return (
        <div className={styles.frequentlySearchContainer} data-qa="employer-index-search-frequently-list">
            <Text typography="label-2-regular" style="secondary">
                <span className={styles.frequentlySearchTitle}>{trls[TrlKeys.resume]}</span>
            </Text>
            <div className={styles.frequentlySearchItems}>
                {items.map((name, i) => (
                    // Этот кастом временный, до грядущего редизайна (PORTFOLIO-35273), так как чипсов для такого фона нет
                    <CustomChips key={i} content={name} onClick={handleClick} />
                ))}
            </div>
        </div>
    );
};

export default translation(FrequentlySearch);

// tempexp_36944_file
import { ReactNode } from 'react';

import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Title, Text, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';

import BenefitsChart from 'src/pages/AnonymousEmployer/magritte/Benefits/BenefitsChart/BenefitsChart';

import styles from './benefits-list.less';

interface BenefitsListItemProps {
    title: ReactNode;
    description: ReactNode;
    percent: number;
}

const BenefitsListItem: TranslatedComponent<BenefitsListItemProps> = ({ percent, title, description }) => {
    const { isXS } = useBreakpoint();
    return (
        <div className={styles.benefitsListItem}>
            <BenefitsChart percent={percent} />
            <div>
                <Title Element="h3" size={isXS ? 'small' : 'medium'}>
                    {title}
                </Title>
                <VSpacing default={8} />
                <Text typography="paragraph-2-regular">{description}</Text>
            </div>
        </div>
    );
};

export default translation(BenefitsListItem);

import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Text } from '@hh.ru/magritte-ui';
import { CircleFilledSize16 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { formatToReactComponent } from 'bloko/common/trl';

import styles from './styles.less';

const TrlKeys = {
    employersOnline: 'index.employer.employersOnline',
    employersOne: 'index.employer.employersOnline.one',
    employersSome: 'index.employer.employersOnline.some',
    employersMany: 'index.employer.employersOnline.many',
};

interface EmployersOnlineProps {
    value: number;
}

const EmployersOnline: TranslatedComponent<EmployersOnlineProps> = ({ value, trls }) => {
    return (
        <div className={styles.employersOnlineContainer}>
            <CircleFilledSize16 initial="positive" />
            <Text style="secondary">
                {formatToReactComponent(trls[TrlKeys.employersOnline], {
                    '{0}': (
                        <b>
                            <Conversion
                                value={value}
                                one={trls[TrlKeys.employersOne]}
                                some={trls[TrlKeys.employersSome]}
                                many={trls[TrlKeys.employersMany]}
                            />
                        </b>
                    ),
                })}
            </Text>
        </div>
    );
};

export default translation(EmployersOnline);

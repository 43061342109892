// tempexp_36944_file
import { TranslatedComponent, translation, formatToReactComponent, format } from '@hh.ru/front-static-app';
import { useBreakpoint, VSpacingContainer } from '@hh.ru/magritte-ui';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useIsRabotaBy } from 'src/hooks/useSites';
import LandingTooltip from 'src/pages/AnonymousEmployer/magritte/LandingTooltip';

import CandidatesListItem from 'src/pages/AnonymousEmployer/magritte/Candidates/CandidatesListItem';
import { CandidateParagraph } from 'src/pages/AnonymousEmployer/magritte/Candidates/types';
import { useResumeCount } from 'src/pages/AnonymousEmployer/magritte/Candidates/useResumeCount';

const TrlKeys = {
    [CandidateParagraph.Trust]: {
        title: 'index.employer.candidates.trust.title',
        description: 'index.employer.candidates.trust.description',
        highlight: 'index.employer.candidates.trust.place',
        tipTitle: 'index.employer.candidates.trust.tip.title',
        tip: 'index.employer.candidates.trust.tip',
    },
    [CandidateParagraph.Here]: {
        title: 'index.employer.candidates.here.title',
        description: 'index.employer.candidates.here.description',
        highlight: 'index.employer.candidates.here.place',
        tipTitle: 'index.employer.candidates.here.tip.title',
        tip: 'index.employer.candidates.here.tip',
    },
    [CandidateParagraph.Quality]: {
        title: 'index.employer.candidates.quality.title',
        description: 'index.employer.candidates.quality.description',
    },
    [CandidateParagraph.Interested]: {
        title: 'index.employer.candidates.interested.title',
        description: 'index.employer.candidates.interested.description',
        highlight: 'index.employer.candidates.interested.place',
        tipTitle: 'index.employer.candidates.interested.tip.title',
        tip: 'index.employer.candidates.interested.tip',
    },
};

const CandidatesList: TranslatedComponent = ({ trls }) => {
    const { isXS } = useBreakpoint();
    const resumeCount = useResumeCount();

    const isRabotaBy = useIsRabotaBy();
    const isZarplata = useIsZarplataPlatform();
    const spacing = isXS ? 24 : 40;

    if (isZarplata) {
        return (
            <VSpacingContainer default={spacing}>
                <CandidatesListItem title={trls[TrlKeys.trust.title]} description={trls[TrlKeys.trust.description]} />
                <CandidatesListItem title={trls[TrlKeys.here.title]} description={trls[TrlKeys.here.description]} />
                <CandidatesListItem
                    title={trls[TrlKeys.quality.title]}
                    description={trls[TrlKeys.quality.description]}
                />
                <CandidatesListItem
                    title={trls[TrlKeys.interested.title]}
                    description={trls[TrlKeys.interested.description]}
                />
            </VSpacingContainer>
        );
    }

    if (isRabotaBy) {
        return (
            <VSpacingContainer default={spacing}>
                <CandidatesListItem
                    title={trls[TrlKeys.trust.title]}
                    description={formatToReactComponent(trls[TrlKeys.trust.description], {
                        '{0}': (
                            <LandingTooltip
                                title={trls[TrlKeys.trust.tipTitle]}
                                tooltipText={trls[TrlKeys.trust.tip]}
                                data-qa={`employer-index-trust-tip-link`}
                            >
                                {trls[TrlKeys.trust.highlight]}
                            </LandingTooltip>
                        ),
                    })}
                />
                <CandidatesListItem
                    title={trls[TrlKeys.here.title]}
                    description={formatToReactComponent(trls[TrlKeys.here.description], {
                        '{0}': resumeCount,
                        '{1}': (
                            <LandingTooltip
                                title={trls[TrlKeys.here.tipTitle]}
                                tooltipText={trls[TrlKeys.here.tip]}
                                data-qa={`employer-index-here-tip-link`}
                            >
                                {trls[TrlKeys.here.highlight]}
                            </LandingTooltip>
                        ),
                    })}
                />
                <CandidatesListItem
                    title={trls[TrlKeys.interested.title]}
                    description={formatToReactComponent(trls[TrlKeys.interested.description], {
                        '{0}': (
                            <LandingTooltip
                                title={trls[TrlKeys.interested.tipTitle]}
                                tooltipText={trls[TrlKeys.interested.tip]}
                                data-qa={`employer-index-interested-tip-link`}
                            >
                                {trls[TrlKeys.interested.highlight]}
                            </LandingTooltip>
                        ),
                    })}
                />
            </VSpacingContainer>
        );
    }

    return (
        <VSpacingContainer default={spacing}>
            <CandidatesListItem
                title={trls[TrlKeys.trust.title]}
                description={formatToReactComponent(trls[TrlKeys.trust.description], {
                    '{0}': (
                        <LandingTooltip
                            title={trls[TrlKeys.trust.tipTitle]}
                            tooltipText={trls[TrlKeys.trust.tip]}
                            data-qa={`employer-index-trust-tip-link`}
                        >
                            {trls[TrlKeys.trust.highlight]}
                        </LandingTooltip>
                    ),
                })}
            />
            <CandidatesListItem
                title={trls[TrlKeys.here.title]}
                description={format(trls[TrlKeys.here.description], {
                    '{0}': resumeCount,
                })}
            />
            <CandidatesListItem title={trls[TrlKeys.quality.title]} description={trls[TrlKeys.quality.description]} />
            <CandidatesListItem
                title={trls[TrlKeys.interested.title]}
                description={trls[TrlKeys.interested.description]}
            />
        </VSpacingContainer>
    );
};

export default translation(CandidatesList);

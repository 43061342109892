import { FC } from 'react';
import classnames from 'classnames';

import employerMainEmployerIndexPublishVacancyElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_employer_index_publish_vacancy_element_shown';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import Vacancy from 'src/pages/AnonymousEmployer/components/PublishVacancy/Vacancy';

import styles from './publish-vacancy.less';

const PublishVacancy: FC = () => {
    const isZarplata = useIsZarplataPlatform();
    return (
        <div
            className={classnames(styles.wrapper, {
                [styles.wrapperWide]: isZarplata,
            })}
        >
            <ElementShownAnchor fn={employerMainEmployerIndexPublishVacancyElementShown}>
                <ColumnsWrapper>
                    <Column xs="0" s="1" m="1" l="3" />
                    <Vacancy />
                </ColumnsWrapper>
            </ElementShownAnchor>
        </div>
    );
};

export default PublishVacancy;

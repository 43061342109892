import { useEffect, useRef, FC } from 'react';

import scrollToElement from 'Utils/ScrollToElement';
import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru, useIsMiddleAsia } from 'src/hooks/useSites';

import MagritteAnonymousEmployer from 'src/pages/AnonymousEmployer/MagritteAnonymousEmployer';
import Benefits from 'src/pages/AnonymousEmployer/components/Benefits';
import Candidates from 'src/pages/AnonymousEmployer/components/Candidates';
import Price from 'src/pages/AnonymousEmployer/components/Price';
import PublishVacancy from 'src/pages/AnonymousEmployer/components/PublishVacancy';
import Search from 'src/pages/AnonymousEmployer/components/Search';
import SearchSteps from 'src/pages/AnonymousEmployer/components/SearchSteps';
import Support from 'src/pages/AnonymousEmployer/components/Support';
import { useAnonymousEmployerMagritte } from 'src/pages/AnonymousEmployer/useAnonymousEmployerMagritte';

const AnonymousEmployerPage: FC = () => {
    const pageMetaData = useSelector((state) => state.pageMetaData);
    const {
        query: { target },
        key,
    } = useSelector((state) => state.router.location);
    const shouldShowPrice = useIsHhru();
    const componentKey = useRef('');
    const priceRef = useRef(null);
    const addEventPixelsRu = useAddEventPixelsRu();
    const sendDV360Analytics = useDV360Analytics();
    const isMagritte = useAnonymousEmployerMagritte();

    useEffect(() => {
        if (shouldShowPrice && componentKey.current !== key && target === 'price' && priceRef.current) {
            scrollToElement(priceRef.current, { centered: false, topOffset: 0 });

            componentKey.current = key!;
        }
    }, [key, priceRef, target, shouldShowPrice]);

    useEffect(() => {
        addEventPixelsRu('B2B_EMPLOYER_PAGE_SHOWN');
    }, [addEventPixelsRu]);

    useEffect(() => {
        sendDV360Analytics('ANONYMOUS_EMPLOYER_SCREEN_SHOWN');
    }, [sendDV360Analytics]);

    const isMiddleAsia = useIsMiddleAsia();
    useEffect(() => {
        if (isMiddleAsia && componentKey.current !== key && target === 'price' && priceRef.current) {
            scrollToElement(priceRef.current, { centered: false, topOffset: 0 });
            componentKey.current = key!;
        }
    }, [key, priceRef, target, isMiddleAsia]);

    if (isMagritte) {
        return (
            <MagritteWrapper isEnabled>
                <PageLayout {...pageMetaData} layout={EmptyLayout}>
                    <MagritteAnonymousEmployer />
                </PageLayout>
            </MagritteWrapper>
        );
    }

    if (isMiddleAsia) {
        return (
            <PageLayout {...pageMetaData} layout={EmptyLayout}>
                <PublishVacancy />
                <Search />
                <Price priceRef={priceRef} />
                <SearchSteps />
                <Support />
            </PageLayout>
        );
    }

    return (
        <PageLayout {...pageMetaData} layout={EmptyLayout}>
            <PublishVacancy />
            <Search isRegularHighlight={shouldShowPrice} />
            {shouldShowPrice && <Price priceRef={priceRef} />}
            <SearchSteps isRegularHighlight={shouldShowPrice} />
            <Benefits isRegularHighlight={!shouldShowPrice} />
            <Candidates isRegularHighlight={shouldShowPrice} />
            <Support />
        </PageLayout>
    );
};

export default AnonymousEmployerPage;

// tempexp_36944_file
import employerMainEmployerIndexPublishVacancyButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_employer_index_publish_vacancy_button_click';
import employerMainPageButtonClickExternal from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_page_button_click';
import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Link } from '@hh.ru/redux-spa-middleware';

import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import LandingButton from 'src/pages/AnonymousEmployer/magritte/LandingButton/LandingButton';

const TrlKeys = {
    publishVacancy: 'index.employer.publishVacancy',
};

const PublishVacancyButton: TranslatedComponent = ({ trls }) => {
    const sendDV360Analytics = useDV360Analytics();

    const sendButtonClickAnalytics = () => {
        employerMainPageButtonClickExternal();
        sendDV360Analytics('ANONYMOUS_EMPLOYER_PAGE_PUBLISH_VACANCY_BUTTON_CLICK');
        employerMainEmployerIndexPublishVacancyButtonClick();
    };

    return (
        <LandingButton
            Element={Link}
            data-qa="employer-index-publish-vacancy"
            to="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
            onClick={sendButtonClickAnalytics}
            size="large"
            style="accent"
            mode="primary"
        >
            {trls[TrlKeys.publishVacancy]}
        </LandingButton>
    );
};

export default translation(PublishVacancyButton);

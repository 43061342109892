// tempexp_36944_file
import { useState, useEffect } from 'react';

const easeFunctions = {
    linear: (t: number) => t,
    ease: (t: number) => t * (2 - t),
    'ease-in': (t: number) => t * t,
    'ease-out': (t: number) => t * (2 - t),
    'ease-in-out': (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
} as const;

interface UseAnimatedNumberProps {
    value: number;
    duration: number;
    delay?: number;
    startAnimation?: boolean;
    timingFunction?: keyof typeof easeFunctions;
}

const useAnimatedNumber = ({
    value,
    duration,
    delay = 0,
    startAnimation = true,
    timingFunction = 'linear',
}: UseAnimatedNumberProps): number => {
    const [currentNumber, setCurrentNumber] = useState(0);

    useEffect(() => {
        if (!startAnimation) {
            return undefined;
        }

        const timer = setTimeout(() => {
            const startTime = performance.now();

            const animateNumber = (time: number) => {
                const timeElapsed = time - startTime;
                const progress = Math.min(timeElapsed / duration, 1);
                const easingProgress = easeFunctions[timingFunction](progress);

                setCurrentNumber(Math.floor(easingProgress * value));

                if (progress < 1) {
                    requestAnimationFrame(animateNumber);
                }
            };

            requestAnimationFrame(animateNumber);
        }, delay);

        return () => clearTimeout(timer);
    }, [value, duration, delay, startAnimation, timingFunction]);

    return currentNumber;
};

export default useAnimatedNumber;

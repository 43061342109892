import { Fragment } from 'react';

import { VendorAnalytics } from '@hh.ru/analytics-js';
import employerMainFrequentSearchRequestButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_frequent_search_request_button_click';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface FrequentlySearchProps {
    items: string[];
    onItemClick: (name: string) => void;
}

const TrlKeys = {
    resume: 'index.employer.search.frequently.resume',
};

const FrequentlySearch: TranslatedComponent<FrequentlySearchProps> = ({ trls, items, onItemClick }) => {
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    const handleClick = (name: string): void => {
        VendorAnalytics.eventToStorage('anonymous', 'click', 'button_pressed');
        onItemClick(name);
        employerMainFrequentSearchRequestButtonClick({
            professionName: name,
        });
    };

    return (
        <div className="employer-index-frequently-list" data-qa="employer-index-search-frequently-list">
            <span className="employer-index-frequently-list-title">
                <Text size={isXs ? TextSize.Medium : TextSize.Large} Element="span" importance={TextImportance.Primary}>
                    {trls[TrlKeys.resume]}
                </Text>
            </span>
            {items.map((name, i) => (
                <Fragment key={i}>
                    <button
                        type="button"
                        data-qa="employer-index-search-frequently-item"
                        className={`employer-index-frequently-list-item employer-index-frequently-list-item_${i}`}
                        onClick={() => handleClick(name)}
                    >
                        <Text size={TextSize.Medium}>
                            <span className="employer-index-frequently-list-item-text">{name}</span>
                        </Text>
                    </button>
                </Fragment>
            ))}
        </div>
    );
};

export default translation(FrequentlySearch);

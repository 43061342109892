// tempexp_36944_file
import { GridColumn, Title, VSpacing, Card, GridRow, GridLayout } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SupportActions from 'src/components/Support/SupportActions/SupportActions';
import SupportContacts from 'src/components/Support/SupportContacts/SupportContacts';
import SupportMessengers from 'src/components/Support/SupportMessengers/SupportMessengers';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useIsHhru } from 'src/hooks/useSites';

import styles from './support.less';

const TrlKeys = {
    title: 'index.employer.help.title',
    subtitle: 'index.employer.help.subtitle',
};

const Support: TranslatedComponent = ({ trls }) => {
    const isHHRu = useIsHhru();
    const isZp = useIsZarplataPlatform();

    return (
        <GridLayout>
            <GridRow>
                <GridColumn xs={4} s={8} m={12}>
                    <VSpacing default={80} />
                    <div className={styles.support}>
                        <Title
                            Element="h2"
                            description={trls[TrlKeys.subtitle]}
                            size="extra-large"
                            descriptionStyle="secondary"
                            alignment="center"
                        >
                            {trls[TrlKeys.title]}
                        </Title>
                        <VSpacing default={48} />
                        {!isZp && (
                            <>
                                <SupportActions />
                                <VSpacing default={isHHRu ? 48 : 24} />
                            </>
                        )}
                        {isHHRu && (
                            <>
                                <SupportMessengers />
                                <VSpacing default={12} />
                            </>
                        )}
                        <Card stretched padding={32} borderRadius={24} style="neutral">
                            <SupportContacts />
                        </Card>
                    </div>
                    <VSpacing default={80} />
                </GridColumn>
            </GridRow>
        </GridLayout>
    );
};

export default translation(Support);

// tempexp_36944_file
import { useMemo, useRef } from 'react';

import { formatNewLine, format, TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Text, Link, TooltipHover, useBreakpoint, BottomSheet, NavigationBar, VSpacing } from '@hh.ru/magritte-ui';

import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHeadhunterKg } from 'src/hooks/useSites';
import useToggleState from 'src/hooks/useToggleState';
import { REGULAR_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    description1: 'index.employer.publishVacancy.description.1',
    description2: 'index.employer.publishVacancy.description.2',
    description3: 'index.employer.publishVacancy.description.3',
    descriptionWithoutTooltip: 'index.employer.publishVacancy.description.withoutTooltip',
    tooltip: 'index.employer.publishVacancy.description.tooltip',
};

const Description: TranslatedComponent = ({ trls }) => {
    const isHeadHunterPlatform = useIsHeadHunterPlatform();
    const isHeadhunterKg = useIsHeadhunterKg();
    const linkRef = useRef(null);
    const statisticsDate = useSelector((state) => state.anonymousEmployer.statisticsDate);
    const { isMobile, isXS } = useBreakpoint();
    const [isBottomSheetOpened, toggleBottomSheet] = useToggleState(false);

    const tooltip = useMemo(() => {
        if (!statisticsDate) {
            return null;
        }

        if (isMobile) {
            return (
                <BottomSheet
                    visible={isBottomSheetOpened}
                    header={
                        <NavigationBar
                            title={<span className={styles.bottomSheetTitle}>{trls[TrlKeys.description2]}</span>}
                        />
                    }
                    onClose={toggleBottomSheet}
                >
                    <span suppressHydrationWarning>
                        <Text typography="label-2-regular">
                            {format(trls[TrlKeys.tooltip], {
                                '{0}': statisticsDate,
                            })}
                        </Text>
                    </span>
                    <VSpacing default={40} />
                </BottomSheet>
            );
        }
        return (
            <TooltipHover activatorRef={linkRef} placement="right-center" data-qa="employer-index-publish-footnote">
                <span suppressHydrationWarning>
                    {format(trls[TrlKeys.tooltip], {
                        '{0}': statisticsDate,
                    })}
                </span>
            </TooltipHover>
        );
    }, [isBottomSheetOpened, isMobile, statisticsDate, toggleBottomSheet, trls]);

    if (isHeadhunterKg) {
        return (
            <Text data-qa="employer-index-subtitle" typography="label-2-regular" style="secondary">
                {trls[TrlKeys.descriptionWithoutTooltip]}
            </Text>
        );
    }

    if (!isHeadHunterPlatform) {
        return null;
    }

    return (
        <div className={styles.description}>
            <Text data-qa="employer-index-subtitle" typography="label-2-regular" style="secondary">
                {isXS ? trls[TrlKeys.description1] : formatNewLine(trls[TrlKeys.description1])}
                <Link onClick={toggleBottomSheet} ref={linkRef} inline>
                    {REGULAR_SPACE}
                    {trls[TrlKeys.description2]}
                    {REGULAR_SPACE}
                </Link>
                {trls[TrlKeys.description3]}
                {statisticsDate && tooltip}
            </Text>
        </div>
    );
};

export default translation(Description);

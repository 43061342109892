// tempexp_36944_file
import { FC } from 'react';

import employerMainEmployerIndexPublishVacancyElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_employer_index_publish_vacancy_element_shown';
import { VSpacing } from '@hh.ru/magritte-ui';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import Centered from 'src/pages/AnonymousEmployer/magritte/Centered/Centered';

import Description from 'src/pages/AnonymousEmployer/magritte/PublishVacancy/Description/Description';
import Header from 'src/pages/AnonymousEmployer/magritte/PublishVacancy/Header/Header';
import PublishVacancyButton from 'src/pages/AnonymousEmployer/magritte/PublishVacancy/PublishVacancyButton';

import styles from './styles.less';

const PublishVacancy: FC = () => {
    return (
        <Centered vertical>
            <ElementShownAnchor fn={employerMainEmployerIndexPublishVacancyElementShown}>
                <div className={styles.publishVacancy}>
                    <Header />
                    <VSpacing default={16} />
                    <Description />
                    <VSpacing default={32} />
                    <PublishVacancyButton />
                </div>
            </ElementShownAnchor>
        </Centered>
    );
};

export default PublishVacancy;

// tempexp_36944_file
import { TranslatedComponent, formatToReactComponent, translation } from '@hh.ru/front-static-app';

import { useIsRabotaBy } from 'src/hooks/useSites';
import { Benefits } from 'src/pages/AnonymousEmployer/magritte/Benefits/types';
import BenefitTooltip from 'src/pages/AnonymousEmployer/magritte/LandingTooltip';

import BenefitsListItem from 'src/pages/AnonymousEmployer/magritte/Benefits/BenefitsList/BenefitsListItem';

import styles from './benefits-list.less';

const TrlKeys = {
    [Benefits.Applicants]: {
        title: 'index.employer.benefits.list.applicants.title',
        description: 'index.employer.benefits.list.applicants.description',
        highlight: 'index.employer.benefits.list.applicants.highlight',
        tip: 'index.employer.benefits.list.applicants.tip',
        tipTitle: 'index.employer.benefits.list.applicants.tip.title',
    },
    [Benefits.Vacancies]: {
        title: 'index.employer.benefits.list.vacancies.title',
        description: 'index.employer.benefits.list.vacancies.description',
        highlight: 'index.employer.benefits.list.vacancies.highlight',
        tip: 'index.employer.benefits.list.vacancies.tip',
        tipTitle: 'index.employer.benefits.list.vacancies.tip.title',
    },
    [Benefits.Employers]: {
        title: 'index.employer.benefits.list.employers.title',
        description: 'index.employer.benefits.list.employers.description',
        highlight: 'index.employer.benefits.list.employers.highlight',
        tip: 'index.employer.benefits.list.employers.tip',
        tipTitle: 'index.employer.benefits.list.employers.tip.title',
    },
};

const PercentValues = {
    RU: {
        [Benefits.Applicants]: 57,
        [Benefits.Vacancies]: 90,
        [Benefits.Employers]: 72,
    },
    BY: {
        [Benefits.Applicants]: 41,
        [Benefits.Vacancies]: 71,
        [Benefits.Employers]: 95,
    },
} as const;

const BenefitsList: TranslatedComponent = ({ trls }) => {
    const isRabotaBy = useIsRabotaBy();
    const percentValues = PercentValues[isRabotaBy ? 'BY' : 'RU'];
    return (
        <div className={styles.benefitsList}>
            {Object.keys(Benefits).map((benefit) => {
                const type = Benefits[benefit];
                return (
                    <BenefitsListItem
                        title={trls[TrlKeys[type].title]}
                        description={formatToReactComponent(trls[TrlKeys[type].description], {
                            '{0}': (
                                <BenefitTooltip
                                    title={trls[TrlKeys[type].tipTitle]}
                                    tooltipText={trls[TrlKeys[type].tip]}
                                    data-qa={`employer-index-${type}-tip-link`}
                                >
                                    {trls[TrlKeys[type].highlight]}
                                </BenefitTooltip>
                            ),
                        })}
                        percent={percentValues[type]}
                        key={type}
                    />
                );
            })}
        </div>
    );
};

export default translation(BenefitsList);

import { useMemo } from 'react';

import { useSelector } from 'src/hooks/useSelector';

const useSupportActions = () => {
    const headerMenu = useSelector(({ headerMenu }) => headerMenu);
    const helpMenuActions = useMemo(() => {
        const { subItems: menuLvl2Items } = headerMenu.find((menuItem) => menuItem.name === 'menuLvl2') || {};
        const { subItems: helpMenuItems } =
            menuLvl2Items?.find((menuItem) => ['anonSupport', 'help', 'support'].includes(menuItem.name)) || {};
        const { subItems: result } = helpMenuItems?.find((item) => item.name === 'helpButtonLinks') || {};
        return result;
    }, [headerMenu]);

    return helpMenuActions || [];
};

export default useSupportActions;

import employerMainStartRecruitingButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_start_recruiting_button_click';
import employerMainStartRecruitingElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_start_recruiting_element_shown';
import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Title, VSpacing, GridColumn, GridRow, GridLayout } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { formatNewLine } from 'bloko/common/trl';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import Centered from 'src/pages/AnonymousEmployer/magritte/Centered/Centered';
import LandingButton from 'src/pages/AnonymousEmployer/magritte/LandingButton/LandingButton';

import EmployersOnline from 'src/pages/AnonymousEmployer/magritte/SearchSteps/EmployersOnline';
import Steps from 'src/pages/AnonymousEmployer/magritte/SearchSteps/Steps';

import styles from './styles.less';

const TrlKeys = {
    title: 'index.employer.steps.title',
    start: 'index.employer.steps.start',
};

const SearchSteps: TranslatedComponent = ({ trls }) => {
    const averageEmployersOnline = useSelector(({ anonymousEmployer }) => anonymousEmployer.averageEmployersOnline);
    const sendAnalytics = () => {
        employerMainStartRecruitingButtonClick();
    };
    const isHhru = useIsHhru();

    return (
        <GridLayout>
            <ElementShownAnchor fn={employerMainStartRecruitingElementShown}>
                <GridRow>
                    <VSpacing default={80} xs={60} />
                    <Centered>
                        <div className={styles.searchStepsContainer}>
                            <GridColumn xs={4} s={8} m={12} l={12}>
                                <Title Element="h2" size="large" alignment="center">
                                    {formatNewLine(trls[TrlKeys.title])}
                                </Title>
                            </GridColumn>
                            <VSpacing default={40} />
                            <Steps />
                            <VSpacing default={40} />
                            <GridColumn xs={4} s={4} m={4} l={3}>
                                <LandingButton
                                    Element={Link}
                                    to="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
                                    data-qa="employer-index-start_recruiting"
                                    mode="primary"
                                    size="large"
                                    stretched
                                    onClick={sendAnalytics}
                                >
                                    {trls[TrlKeys.start]}
                                </LandingButton>
                            </GridColumn>
                            {isHhru && averageEmployersOnline && (
                                <>
                                    <VSpacing default={40} />
                                    <EmployersOnline value={averageEmployersOnline} />
                                </>
                            )}
                        </div>
                    </Centered>
                    <VSpacing default={80} xs={60} />
                </GridRow>
            </ElementShownAnchor>
        </GridLayout>
    );
};

export default translation(SearchSteps);

// tempexp_36944_file
import { FC, ReactNode } from 'react';

import { useBreakpoint, Title, Text, VSpacing } from '@hh.ru/magritte-ui';

interface CandidatesListItemProps {
    title: ReactNode;
    description: ReactNode;
}

const CandidatesListItem: FC<CandidatesListItemProps> = ({ title, description }) => {
    const { isXS } = useBreakpoint();

    return (
        <div>
            <Title Element="h3" size={isXS ? 'small' : 'medium'}>
                {title}
            </Title>
            <VSpacing default={8} />
            <Text typography="paragraph-2-regular">{description}</Text>
        </div>
    );
};

export default CandidatesListItem;

// Этот кастом временный, до грядущего редизайна PORTFOLIO-35273
import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface CustomChips {
    content: string;
    onClick: (val: string) => void;
}

const CustomChips: FC<CustomChips> = ({ content, onClick }) => {
    return (
        <button
            type="button"
            data-qa="employer-index-search-frequently-item"
            className={styles.chipsItem}
            onClick={() => onClick(content)}
        >
            <Text typography="subtitle-1-semibold">
                <span className={styles.chipsItemText}>{content}</span>
            </Text>
        </button>
    );
};

export default CustomChips;

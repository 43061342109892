import { useRef, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import employerMainFindEmployeeButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_find_employee_button_click';
import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { GridColumn, VSpacing } from '@hh.ru/magritte-ui';

import paths from 'src/app/routePaths';
import Form from 'src/components/Form';
import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import LandingButton from 'src/pages/AnonymousEmployer/magritte/LandingButton/LandingButton';

import Fieldset from 'src/pages/AnonymousEmployer/magritte/ResumeSearch/Fieldset';
import FrequentlySearch from 'src/pages/AnonymousEmployer/magritte/ResumeSearch/FrequentlySearch';
import SearchInput from 'src/pages/AnonymousEmployer/magritte/ResumeSearch/SearchInput';

import styles from './styles.less';

const TrlKeys = {
    find: 'anonymousEmployer.resumeSearch.form.find',
};

const SearchForm: TranslatedComponent = ({ trls }) => {
    const professions = useSelector((state) => state.anonymousEmployer.suggestedProfessions);
    const addEventPixelsRu = useAddEventPixelsRu();
    const isZP = useIsZarplataPlatform();
    const sendDV360Analytics = useDV360Analytics();
    const [searchText, setSearchText] = useState('');

    const sendButtonAnalytics = () => {
        sendDV360Analytics('ANONYMOUS_EMPLOYER_PAGE_FIND_EMPLOYMENT_BUTTON_CLICK');
        if (isZP) {
            Analytics.addEventPixels('ZP_B2B_SEARCH_RESUME');
        }
        employerMainFindEmployeeButtonClick({
            text: searchText,
        });
    };

    const sendSubmitAnalytics = () => {
        addEventPixelsRu('B2B_EMPLOYER_PAGE_SEARCH');
    };

    const formRef = useRef<HTMLFormElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const submitForm = () => {
        sendSubmitAnalytics();
        formRef.current?.submit();
    };

    const setInputValueAndSubmit = (suggest: string): void => {
        if (inputRef.current) {
            inputRef.current.value = suggest;
        }
        submitForm();
    };

    return (
        <GridColumn xs={4} s={8} m={10} l={10}>
            <Form action={paths.resumeSearch} method="GET" ref={formRef} onSubmit={sendSubmitAnalytics}>
                <Fieldset />
                <div className={styles.searchFormContainer}>
                    <div className={styles.searchGroup}>
                        <GridColumn xs={4} s={6} m={7} l={8}>
                            <SearchInput
                                searchText={searchText}
                                onSearchTextChange={setSearchText}
                                onSuggestPick={submitForm}
                            />
                        </GridColumn>
                        <GridColumn xs={0} s={2} m={3} l={2}>
                            <LandingButton
                                type="submit"
                                mode="primary"
                                size="large"
                                stretched
                                onClick={sendButtonAnalytics}
                                data-qa="employer-index-search-submit"
                            >
                                {trls[TrlKeys.find]}
                            </LandingButton>
                        </GridColumn>
                    </div>
                    <VSpacing default={28} />
                    <GridColumn xs={4} s={8} m={10} l={10}>
                        <FrequentlySearch items={professions} onItemClick={setInputValueAndSubmit} />
                    </GridColumn>
                </div>
            </Form>
        </GridColumn>
    );
};

export default translation(SearchForm);

import { useRef } from 'react';

import employerMainPublishVacancyOfferButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_publish_vacancy_offer_button_click';
import employerMainPublishVacancyOfferElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_publish_vacancy_offer_element_shown';
import { GiftOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import Info from 'bloko/blocks/drop/Info';
import { FormSpacer } from 'bloko/blocks/form';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import LandingButton from 'src/pages/AnonymousEmployer/components/LandingButton';
import IndexSection, { HighlightType } from 'src/pages/AnonymousEmployer/components/Section';
import Title from 'src/pages/AnonymousEmployer/components/Title';

import BenefitsList from 'src/pages/AnonymousEmployer/components/Benefits/BenefitsList';
import BenefitsListZarplata from 'src/pages/AnonymousEmployer/components/Benefits/BenefitsListZarplata';

interface BenefitsProps {
    isRegularHighlight?: boolean;
}

const TrlKeys = {
    title: 'index.employer.benefits.title',
    description: 'index.employer.benefits.description',
    action: 'index.employer.benefits.action',
    actionNoPrice: 'index.employer.benefits.action.no.price',
    gift: 'index.employer.benefits.gift',
    giftNumber: 'index.employer.benefits.gift.number',
    giftTip: 'index.employer.benefits.gift.tip',
};

const Benefits: TranslatedComponent<BenefitsProps> = ({ trls, isRegularHighlight }) => {
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;
    const [isInfoVisible, showInfo, hideInfo] = useOnOffState(false);
    const publicationPrice = useSelector(({ anonymousEmployer }) => anonymousEmployer.publicationPrice);
    const { currency, price } = publicationPrice || {};
    const isZP = useIsZarplataPlatform();
    const isHHru = useIsHhru();
    const activatorRef = useRef(null);

    const sendAnalytics = () => {
        employerMainPublishVacancyOfferButtonClick();
    };

    return (
        <IndexSection highlight={isZP || !isRegularHighlight ? HighlightType.Light : HighlightType.Regular}>
            <ElementShownAnchor fn={employerMainPublishVacancyOfferElementShown}>
                <ColumnsWrapper>
                    <Column xs="4" s="8" m="6" l="7">
                        <Title>{trls[TrlKeys.title]}</Title>
                        <VSpacing base={10} xs={8} />
                        {!isZP && (
                            <Text size={isXs ? TextSize.Medium : TextSize.Large}>{trls[TrlKeys.description]}</Text>
                        )}
                        <VSpacing base={10} xs={8} />
                        <LandingButton
                            Element={Link}
                            data-qa="employer-index-publish-vacancy-offer"
                            to="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
                            onClick={sendAnalytics}
                        >
                            <span className="employer-index-action-label">
                                {!isZP && price && currency ? (
                                    <FormatMoney
                                        currency={currency}
                                        inCents
                                        render={(amount, currencyNode) =>
                                            formatToReactComponent(trls[TrlKeys.action], {
                                                '{0}': (
                                                    <>
                                                        <span data-qa="employer-index-vacancy-price__amount">
                                                            {amount}
                                                        </span>{' '}
                                                        <span data-qa="employer-index-vacancy-price__currency">
                                                            {currencyNode}
                                                        </span>
                                                    </>
                                                ),
                                            })
                                        }
                                    >
                                        {price}
                                    </FormatMoney>
                                ) : (
                                    trls[TrlKeys.actionNoPrice]
                                )}
                            </span>
                        </LandingButton>
                        <VSpacing base={5} xs={3} />
                        {isHHru && (
                            <div>
                                <BlokoLink>
                                    <GiftOutlinedSize16 />
                                </BlokoLink>
                                <FormSpacer>
                                    {formatToReactComponent(trls[TrlKeys.gift], {
                                        '{0}': (
                                            <Info
                                                show={isInfoVisible}
                                                render={() => trls[TrlKeys.giftTip]}
                                                onClose={hideInfo}
                                                activatorRef={activatorRef}
                                            >
                                                <BlokoLink
                                                    onClick={showInfo}
                                                    data-qa={`employer-index-gift-tip-link`}
                                                    appearance={LinkAppearance.Pseudo}
                                                    ref={activatorRef}
                                                >
                                                    {trls[TrlKeys.giftNumber]}
                                                </BlokoLink>
                                            </Info>
                                        ),
                                    })}
                                </FormSpacer>
                            </div>
                        )}
                    </Column>
                    <Column xs="0" s="0" m="0" l="2" />
                    <Column xs="4" s="8" m="6" l="7">
                        {isZP ? <BenefitsListZarplata /> : <BenefitsList />}
                    </Column>
                </ColumnsWrapper>
            </ElementShownAnchor>
        </IndexSection>
    );
};

export default translation(Benefits);

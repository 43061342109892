// tempexp_36944_file
import classNames from 'classnames';

import { Button, ButtonProps } from '@hh.ru/magritte-ui';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import styles from './styles.less';

const LandingButton: typeof Button = (props) => {
    const isZp = useIsZarplataPlatform();

    if (isZp) {
        const { Element = 'button', children, stretched, size, style, mode, ...buttonProps } = props;
        const typeAttribute: { type?: ButtonProps['type'] } = {};
        if (Element === 'button') {
            typeAttribute.type = props.type;
        }

        return (
            // Custom yellow button specificly for the anonymous employer landing page
            <Element
                className={classNames(styles.button, {
                    [styles.stretched]: stretched,
                })}
                {...typeAttribute}
                {...buttonProps}
            >
                <div className={styles.buttonView}>
                    <span className={styles.buttonContent}>
                        <span className={styles.buttonLabel}>{children}</span>
                    </span>
                </div>
            </Element>
        );
    }

    return <Button {...props} style="accent" />;
};

export default LandingButton;

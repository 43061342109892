import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Title, useBreakpoint } from '@hh.ru/magritte-ui';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import styles from './styles.less';

const TrlKeys = {
    1: {
        title: 'index.employer.step.1.title',
        description: 'index.employer.step.1.description',
    },
    2: {
        title: 'index.employer.step.2.title',
        description: 'index.employer.step.2.description',
    },
    3: {
        title: 'index.employer.step.3.title',
        description: 'index.employer.step.3.description',
    },
};

const STEPS = [1, 2, 3] as const;

const Steps: TranslatedComponent = ({ trls }) => {
    const { isMobile, isXS } = useBreakpoint();
    const isZarplata = useIsZarplataPlatform();

    return (
        <div className={styles.searchStepContainer}>
            {STEPS.map((step) => (
                <div className={styles.searchStepItem} key={step}>
                    <div className={styles[`searchStepItem${step}`]} />
                    <div className={styles.searchStepTextItem}>
                        <Title
                            Element="h3"
                            size={isMobile ? 'small' : 'medium'}
                            alignment={isXS ? 'left' : 'center'}
                            description={!isZarplata && trls[TrlKeys[step].description]}
                        >
                            {trls[TrlKeys[step].title]}
                        </Title>
                    </div>
                    {!isXS && step !== STEPS.length && <div className={styles.searchStepItemArrow} />}
                </div>
            ))}
        </div>
    );
};

export default translation(Steps);

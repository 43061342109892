import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import employerMainFindEmployeeButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_find_employee_button_click';
import { ButtonType } from 'bloko/blocks/button';
import { IconLink } from 'bloko/blocks/icon';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import { useDV360Analytics } from 'src/hooks/useDV360Analytics';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import LandingButton from 'src/pages/AnonymousEmployer/components/LandingButton';

import Fieldset from 'src/pages/AnonymousEmployer/components/Search/Fieldset';
import FrequentlySearch from 'src/pages/AnonymousEmployer/components/Search/FrequentlySearch';
import SearchInput from 'src/pages/AnonymousEmployer/components/Search/SearchInput';

import styles from './search.less';

const TrlKeys = {
    findEmployment: 'index.employer.search.action.findEmployment',
};

const SearchForm: TranslatedComponent = ({ trls }) => {
    const professions = useSelector((state) => state.anonymousEmployer.suggestedProfessions);
    const addEventPixelsRu = useAddEventPixelsRu();
    const isZP = useIsZarplataPlatform();
    const sendDV360Analytics = useDV360Analytics();
    const formRef = useRef<HTMLFormElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const sendButtonAnalytics = () => {
        sendDV360Analytics('ANONYMOUS_EMPLOYER_PAGE_FIND_EMPLOYMENT_BUTTON_CLICK');
        if (isZP) {
            Analytics.addEventPixels('ZP_B2B_SEARCH_RESUME');
        }
        employerMainFindEmployeeButtonClick({
            text: inputRef?.current?.value || '',
        });
    };

    const sendSubmitAnalytics = () => {
        addEventPixelsRu('B2B_EMPLOYER_PAGE_SEARCH');
    };

    const submitForm = () => {
        sendSubmitAnalytics();
        formRef.current?.submit();
    };

    const setInputValueAndSubmit = (suggest: string): void => {
        if (inputRef.current) {
            inputRef.current.value = suggest;
        }
        submitForm();
    };

    return (
        <Form action={paths.resumeSearch} method="GET" ref={formRef} onSubmit={sendSubmitAnalytics}>
            <Fieldset />
            <div className={styles.searchGroup}>
                <SearchInput innerRef={inputRef} onSuggestPick={submitForm} />
                <div className={styles.searchSubmit}>
                    <LandingButton
                        type={ButtonType.Submit}
                        onClick={sendButtonAnalytics}
                        angular={false}
                        data-qa="employer-index-search-submit"
                    >
                        <span className={styles.searchSubmitLabel}>{trls[TrlKeys.findEmployment]}</span>
                    </LandingButton>
                </div>
                <div className={styles.searchSubmitMobile}>
                    <IconLink
                        Element="button"
                        type="submit"
                        onClick={sendButtonAnalytics}
                        data-qa="employer-index-search-mobile"
                    >
                        <span className="supernova-icon supernova-icon_search-primary" />
                    </IconLink>
                </div>
            </div>
            <VSpacing base={7} xs={5} />
            <FrequentlySearch items={professions} onItemClick={setInputValueAndSubmit} />
        </Form>
    );
};

export default translation(SearchForm);

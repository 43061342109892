// tempexp_36944_file
import employerMainPublishVacancyOfferElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_publish_vacancy_offer_element_shown';
import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { GridColumn, GridRow, Title, VSpacing, GridLayout } from '@hh.ru/magritte-ui';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useIsHhru } from 'src/hooks/useSites';

import BenefitsBanner from 'src/pages/AnonymousEmployer/magritte/Benefits/BenefitsBanner';
import BenefitsList from 'src/pages/AnonymousEmployer/magritte/Benefits/BenefitsList/BenefitsList';
import BenefitsListZarplata from 'src/pages/AnonymousEmployer/magritte/Benefits/BenefitsList/BenefitsListZarplata';
import PublishVacancyButton from 'src/pages/AnonymousEmployer/magritte/Benefits/PublishVacancyButton';

const TrlKeys = {
    title: 'index.employer.benefits.title',
    subtitle: 'index.employer.benefits.subtitle',
    action: 'index.employer.benefits.action',
    actionNoPrice: 'index.employer.benefits.action.no.price',
};

const Benefits: TranslatedComponent = ({ trls }) => {
    const isZP = useIsZarplataPlatform();
    const isHHru = useIsHhru();

    return (
        <GridLayout>
            <VSpacing default={80} />
            <ElementShownAnchor fn={employerMainPublishVacancyOfferElementShown}>
                <GridRow>
                    <GridColumn xs={4} s={8} m={5}>
                        <Title
                            Element="h2"
                            size="extra-large"
                            description={trls[TrlKeys.subtitle]}
                            descriptionStyle="secondary"
                        >
                            {trls[TrlKeys.title]}
                        </Title>
                        <VSpacing default={40} />
                        <PublishVacancyButton />
                        {isHHru && (
                            <>
                                <VSpacing default={16} />
                                <BenefitsBanner />
                            </>
                        )}
                        <VSpacing default={60} />
                    </GridColumn>
                    <GridColumn xs={0} s={0} m={1} />
                    <GridColumn xs={4} s={8} m={6}>
                        {isZP ? <BenefitsListZarplata /> : <BenefitsList />}
                    </GridColumn>
                </GridRow>
            </ElementShownAnchor>
            <VSpacing default={80} />
        </GridLayout>
    );
};

export default translation(Benefits);

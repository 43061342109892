import { RefObject } from 'react';

import employerMainChooseHiringOptionElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/main/employer_main_choose_hiring_option_element_shown';
import { AvatarIconStyle } from '@hh.ru/magritte-ui';
import { GiftOutlinedSize24, CheckCircleOutlinedSize24, ClockOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import NumberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import AnalyticsElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';
import Title from 'src/pages/AnonymousEmployer/components/Title';

import Card from 'src/pages/AnonymousEmployer/components/Price/Card';
import CardRu from 'src/pages/AnonymousEmployer/components/Price/CardRu';
import { CardType } from 'src/pages/AnonymousEmployer/components/Price/types';

import styles from './price.less';

interface PriceProps {
    priceRef: RefObject<HTMLDivElement>;
}

const TrlKeys = {
    ru: {
        title: 'anonEmployer.price.ru.title',
        vacancies: {
            action: 'anonEmployer.price.ru.vacancies.action',
            header: 'anonEmployer.price.ru.vacancies.header',
            description: 'anonEmployer.price.ru.vacancies.description',
            firstOption: 'anonEmployer.price.ru.vacancies.firstOption',
            secondOption: 'anonEmployer.price.ru.vacancies.secondOption',
        },
        candidates: {
            action: 'anonEmployer.price.ru.candidates.action',
            header: 'anonEmployer.price.ru.candidates.header',
            description: 'anonEmployer.price.ru.candidates.description',
            firstOption: 'anonEmployer.price.ru.candidates.firstOption',
            secondOption: 'anonEmployer.price.ru.candidates.secondOption',
        },
    },
    title: 'anonEmployer.price.title',
    vacancies: {
        title: 'anonEmployer.price.vacancies.standart',
        action: 'anonEmployer.price.vacancies.action',
        header: 'anonEmployer.price.vacancies.header',
        description: 'anonEmployer.price.vacancies.description',
        price: 'anonEmployer.price.vacancies.price',
        firstOption: 'anonEmployer.price.vacancies.firstOption',
        secondOption: 'anonEmployer.price.vacancies.secondOption',
    },
    candidates: {
        title: 'anonEmployer.price.candidates.title',
        action: 'anonEmployer.price.candidates.action',
        price: 'anonEmployer.price.candidates.price',
        firstOption: 'anonEmployer.price.candidates.firstOption',
        secondOption: 'anonEmployer.price.candidates.secondOption',
    },
};

const Price: TranslatedComponent<PriceProps> = ({ trls, priceRef }) => {
    const resumeCount = useSelector((state) => state.globalStatistics?.resumes) ?? 0;
    const isHH = useIsHhru();

    return (
        <AnalyticsElementShownAnchor fn={employerMainChooseHiringOptionElementShown}>
            <div className={styles.container} ref={priceRef}>
                <ColumnsWrapper>
                    <Column xs="3" s="8" m={isHH ? '10' : '9'} l={isHH ? '10' : '9'}>
                        <Title>{isHH ? trls[TrlKeys.ru.title] : trls[TrlKeys.title]}</Title>
                        <VSpacing base={12} xs={8} />
                    </Column>
                </ColumnsWrapper>
                {isHH && (
                    <div className={styles.cartsContainer}>
                        <CardRu
                            type={CardType.Vacancies}
                            withTopTag
                            action={trls[TrlKeys.ru.vacancies.action]}
                            header={trls[TrlKeys.ru.vacancies.header]}
                            description={trls[TrlKeys.ru.vacancies.description]}
                            toLink="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
                            cartOptions={[
                                {
                                    icon: <CheckCircleOutlinedSize24 initial="secondary" />,
                                    style: 'secondary' as AvatarIconStyle,
                                    text: trls[TrlKeys.ru.vacancies.firstOption],
                                },
                                {
                                    icon: <GiftOutlinedSize24 initial="special" />,
                                    style: 'special' as AvatarIconStyle,
                                    text: trls[TrlKeys.ru.vacancies.secondOption],
                                },
                            ]}
                        />
                        <CardRu
                            type={CardType.Candidates}
                            action={trls[TrlKeys.ru.candidates.action]}
                            header={trls[TrlKeys.ru.candidates.header]}
                            description={trls[TrlKeys.ru.candidates.description]}
                            toLink="/auth/employer?backurl=/price/dbaccess?hhtmFrom=employer_index_content"
                            cartOptions={[
                                {
                                    icon: <CheckCircleOutlinedSize24 initial="secondary" />,
                                    style: 'secondary' as AvatarIconStyle,
                                    text: trls[TrlKeys.ru.candidates.firstOption],
                                },
                                {
                                    icon: <ClockOutlinedSize24 initial="secondary" />,
                                    style: 'secondary' as AvatarIconStyle,
                                    text: trls[TrlKeys.ru.candidates.secondOption],
                                },
                            ]}
                        />
                    </div>
                )}
                {!isHH && (
                    <div className={styles.cartsContainer}>
                        <Card
                            type={CardType.Vacancies}
                            action={trls[TrlKeys.vacancies.action]}
                            header={trls[TrlKeys.vacancies.title]}
                            cartOptions={[trls[TrlKeys.vacancies.firstOption], trls[TrlKeys.vacancies.secondOption]]}
                            priceTitle={trls[TrlKeys.vacancies.price]}
                            toLink="/auth/employer?backurl=/employer/vacancy/create&from=employer_index_content&hhtmFromLabel=employer_index_content"
                        />
                        <Card
                            type={CardType.Candidates}
                            action={trls[TrlKeys.candidates.action]}
                            header={trls[TrlKeys.candidates.title]}
                            cartOptions={[
                                format(trls[TrlKeys.candidates.firstOption], {
                                    '{0}': NumberFormatter.format(resumeCount.toString()),
                                }),
                                trls[TrlKeys.candidates.secondOption],
                            ]}
                            priceTitle={trls[TrlKeys.candidates.price]}
                            toLink="/auth/employer?backurl=/price/dbaccess?hhtmFrom=employer_index_content"
                            tiny
                        />
                    </div>
                )}
            </div>
        </AnalyticsElementShownAnchor>
    );
};

export default translation(Price);

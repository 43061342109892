// tempexp_36944_file
import { useEffect, useRef } from 'react';

import scrollToElement from 'Utils/ScrollToElement';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru, useIsMiddleAsia } from 'src/hooks/useSites';

const usePriceSection = () => {
    const priceRef = useRef(null);
    const {
        query: { target },
        key,
    } = useSelector((state) => state.router.location);
    const isHHru = useIsHhru();
    const isMiddleAsia = useIsMiddleAsia();
    const shouldShowPrice = isHHru || isMiddleAsia;
    const componentKey = useRef('');

    useEffect(() => {
        if (shouldShowPrice && componentKey.current !== key && target === 'price' && priceRef.current) {
            scrollToElement(priceRef.current, { centered: false, topOffset: 0 });
            componentKey.current = key!;
        }
    }, [key, priceRef, target, shouldShowPrice, isMiddleAsia]);

    return priceRef;
};

export default usePriceSection;

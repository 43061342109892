import { TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Card, Text } from '@hh.ru/magritte-ui';

const TrlKeys = {
    text: 'index.employer.benefits.gifts.banner',
};

const BenefitsBanner: TranslatedComponent = ({ trls }) => {
    return (
        <Card stretched padding={16} borderRadius={16} style="extra-1">
            <Text typography="label-2-regular">{trls[TrlKeys.text]}</Text>
        </Card>
    );
};

export default translation(BenefitsBanner);

import { FC } from 'react';

import { NovaFilterSetting } from 'src/components/Search/Common/Filters/utils';
import { useSelector } from 'src/hooks/useSelector';
import { PosOption, LogicOption, ExpPeriodOption } from 'src/models/search/resume/searchQueries';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

const Fieldset: FC = () => {
    const domainAreaId = useSelector(({ locale }) => locale.domainAreaId);
    return (
        <fieldset>
            <input type="hidden" name={CriteriaKey.Area} value={domainAreaId} />
            <input type="hidden" name={CriteriaKey.IsDefaultArea} value="true" />
            <input type="hidden" name={CriteriaKey.ExpPeriod} value={ExpPeriodOption.AllTime} />
            <input type="hidden" name={CriteriaKey.Logic} value={LogicOption.Normal} />
            <input type="hidden" name={CriteriaKey.Pos} value={PosOption.FullText} />
            <input type="hidden" name={NovaFilterSetting.FromSearchLine} value="true" />
            <input type="hidden" name="from" value="employer_index_header" />
        </fieldset>
    );
};

export default Fieldset;

import { Badge, Button, ComponentWithBadge, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useSupportChatik from 'src/components/SupportChatPlate/useSupportChatik';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import SupportAction from 'src/components/Support/SupportActions/SupportAction';
import SupportActionCell from 'src/components/Support/SupportActions/SupportActionCell';
import SupportActionsLayout from 'src/components/Support/SupportActions/SupportActionsLayout';
import { SUPPORT_ACTIONS_ICONS } from 'src/components/Support/SupportActions/constants';
import useSupportActions from 'src/components/Support/SupportActions/useSupportActions';

const TrlKeys = {
    supportChatContact: 'supernova.modal.help.supportChat.contact',
};

const SupportActions: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;
    const supportActions = useSupportActions();
    const { dataQa, handleChatikClick, unreadCount } = useSupportChatik();

    const isSupportChatEnabled = supportActions.some((item) => item.name === 'supportChat');
    const numberOfCalculatedElements = supportActions.length - (isSupportChatEnabled && !isEmployer ? 1 : 0);

    return (
        <SupportActionsLayout>
            {supportActions.map((supportAction, index) => {
                const commonProps = {
                    url: supportAction.url,
                    external: supportAction.external,
                    icon: SUPPORT_ACTIONS_ICONS[supportAction.name as keyof typeof SUPPORT_ACTIONS_ICONS],
                    iconAriaLabel: supportAction.name,
                    children: supportAction.translations.name,
                };
                const isChatik = supportAction.name === 'supportChat';
                const isWide = (isChatik && !isEmployer) || (!!(numberOfCalculatedElements % 2) && index === 0);
                return (
                    <SupportActionCell key={index} isWide={isWide}>
                        {isChatik ? (
                            <SupportAction
                                {...commonProps}
                                onClick={handleChatikClick}
                                data-qa={dataQa}
                                right={
                                    !isEmployer && !isMobile ? (
                                        <ComponentWithBadge
                                            badge={
                                                unreadCount ? (
                                                    <Badge size="medium">{`${unreadCount}`}</Badge>
                                                ) : undefined
                                            }
                                            offset={-4}
                                        >
                                            <Button onClick={handleChatikClick} mode="secondary" data-qa={dataQa}>
                                                {trls[TrlKeys.supportChatContact]}
                                            </Button>
                                        </ComponentWithBadge>
                                    ) : undefined
                                }
                                badgeCount={isMobile ? unreadCount : undefined}
                            />
                        ) : (
                            <SupportAction {...commonProps} />
                        )}
                    </SupportActionCell>
                );
            })}
        </SupportActionsLayout>
    );
};

export default translation(SupportActions);
